@import '../../../../styles/customMediaQueries.css';

.wrapper {
  display: flex;
  height: 55vh;
  width: 100%;
  background-color: #FEF6FA;
  flex-direction: column;
  padding: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
    padding: unset;
  }
}

.imageWrapper {
  flex: 2;
  background-image: url('./images/image.jpg');
  background-size: contain;
  background-position: center;
  overflow: hidden;
  order: -1;
  height: 50vh;

  @media (--viewportMedium) {
    height: unset;
    order: unset;
  }
}

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;

  @media (--viewportMedium) {
    width: unset;
    padding: 0 9rem;
    align-items: flex-start;

  }
}

.promoText {
  color: black;
  font-family: Neuton;
  margin: 15px 0;
  font-size: 2rem;
  font-weight: normal;

  @media (--viewportMedium) {
    font-size: 3rem;
  }
}

.promoSubtitle {
  color: black;
  font-family: Raleway;
  margin: 20px 0;
  font-size: 1.1rem;
  font-weight: bold;

  @media (--viewportMedium) {
    font-size: 1.5rem;
  }
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

/* 
@media (max-width: 990px) {
  .wrapper {
    flex-direction: column;
    padding: 20px;
  }

  .contentWrapper,
  .imageWrapper {
    width: 100%;
    padding: 0;
  }

  .imageWrapper {
    order: -1;
    height: 50vh;
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    justify-content: center;
    align-items: center;
  }

  .promoText {
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .promoSubtitle {
    color: black;
    font-family: Raleway;
    margin: 20px 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
} */