@import '../../../../styles/customMediaQueries.css';


.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 64px 20%;
}


.title {
  color: black;
  font-family: Neuton;
  font-size: 2rem;
  font-weight: normal;
  text-align: center;
  font-weight: bold;

  @media (--viewportMedium) {
    font-size: 3rem;
  }
}

.info {
  color: black;
  font-family: Raleway;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
}