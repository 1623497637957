@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 32px;
  max-width: var(--contentMaxWidthPages);

  @media (--viewportMedium) {
    padding: 64px 32px 64px 32px;
  }
}

.title {
  color: black;
  font-family: Neuton;
  font-size: 2rem;
  font-weight: normal;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 3rem;
  }
}

.info {
  color: black;
  font-family: Raleway;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
}

.infoHighlight {
  composes: info;
  font-size: 1.5rem;
  text-align: center;
}

.infoBold {
  color: black;
  font-family: Raleway;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}