@import '../../../../styles/customMediaQueries.css';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  justify-content: space-around;
  padding-left: 20%;
  padding-right: 20%;
  /* padding-top: 7%; */
  padding-bottom: 7%;
  gap: 30px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.benefitContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;


}

.benefitImage {
  width: 52px;
  height: 52px;
}

.benefitName {
  color: black;
  font-family: Raleway;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  /* max-width: 180px; */
  padding: 0;
  margin: 0;
}

.benefitInfo {
  color: black;
  font-family: Raleway;
  font-size: 0.9rem;
  font-weight: normal;
  text-align: center;
  /* max-width: 180px; */
  padding: 0;
  margin: 0;
  line-height: 18px;
}