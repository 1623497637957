@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FEF6FA;
}

.wrapper {
  display: grid;
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  padding: 32px;

  @media (--viewportMedium) {
    margin-top: 40px;
    margin-bottom: 40px;
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
    padding: 64px 32px 64px 32px;
  }
}


.imageWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (--viewportMedium) {
    width: 100%;
  }
}

.image {
  width: 100%;
  border-radius: 8px;
  /* margin-bottom: 60px; */
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 32px;

  @media (--viewportMedium) {
    max-width: 220px;
  }
}

.promoText {
  color: black;
  font-family: Neuton;
  margin: 15px 0;
  font-size: 2rem;
  font-weight: bold;
  line-height: 45px;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 2.2rem;
    text-align: unset;
  }
}

.promoSubtitle {
  color: black;
  font-family: Raleway;
  margin: 20px 0;
  font-size: 1.5rem;
  font-weight: bold;
}