@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 10%;
}

.imagesWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;


  @media (--viewportMedium) {
    flex-direction: row;
    align-items: start;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.imageWrapper {
  display: flex;
  width: 80%;
  flex-direction: column;

  @media (--viewportMedium) {
    width: 30%;
  }
}

.image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.promoText {
  color: black;
  font-family: Neuton;
  margin: 15px 0;
  margin-top: 50px;
  font-size: 2rem;
  font-weight: bold;

  @media (--viewportMedium) {
    font-size: 3rem;
    margin: 15px 0;
  }
}

.promoSubtitle {
  color: black;
  font-family: Raleway;
  margin: 20px 0;
  font-size: 1.5rem;
  /* Adjust based on your preference */
  font-weight: bold;
}