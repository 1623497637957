@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FEF6FA;
  padding: 64px 32px 64px 32px;
}

.wrapper {
  display: grid;
  width: 100%;
  max-width: var(--contentMaxWidthPages);

  grid-template-columns: repeat(1, 1fr);

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }

  gap: 32px;
}

.container {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  margin: 0 auto;

  @media (--viewportMedium) {
    gap: 32px;
  }

}

.mainTitle {
  color: black;
  font-family: Neuton;
  font-size: 2rem;
  font-weight: normal;
  padding-bottom: 36px;

  @media (--viewportMedium) {
    font-size: 3rem;
  }
}

.title {
  color: black;
  font-family: Raleway;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: start;
  font-weight: bold;
  padding: 0;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 1.5rem;
  }
}

.info {
  color: black;
  font-family: Raleway;
  font-size: 1rem;
  font-weight: normal;
  padding: 0;
  margin: 0;
}