@import '../../../../styles/customMediaQueries.css';

.wrapper {
  display: flex;
  width: 100%;
  min-height: 75vh;
  background-color: #FEF6FA;
  flex-direction: column;
  padding: 32px;
  


  @media (--viewportMedium) {
    flex-direction: row;
    padding: 64px 32px 64px 32px;
    min-height: 500px;
  }
}

.imageWrapper {
  flex: 1.5;
  background-image: url('./images/image.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  /* margin-left: 32px;
  margin-right: 32px; */
  min-height: 300px;
  /* margin: 62px; */

  @media (--viewportMedium) {
    min-height: unset;
    margin-left: unset;
    margin-right: unset;
    margin: 40px;
    /* background-size: calc(70%); */
  }
}

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* padding: 32px; */

  @media (--viewportMedium) {
    padding-right: 8rem;
    padding-top: 0;
    padding-bottom: 0;
    /* margin-left: 60px; */
  }
}

.button {
  flex-shrink: 0;
  align-self: flex-start;

  @media (--viewportMedium) {
    max-width: 140px;
  }
}

.promoText {
  color: black;
  font-family: Neuton;
  margin: 15px 0;
  font-size: 2rem;
  font-weight: bold;
  align-self: center;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 3rem;
    align-self: unset;
    text-align: unset;
  }
}

.info {
  font-family: Raleway;
}