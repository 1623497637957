@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: var(--contentMaxWidthPages);
  align-items: center;
  justify-content: center;
  padding: 32px;


  @media (--viewportMedium) {
    padding: 64px;
  }
}

.image {
  max-width: 100%;
  margin-top: 32px;
  border-radius: 8px;

  @media (--viewportMedium) {
    max-width: 784px;
  }
}

.title {
  color: black;
  font-family: Neuton;
  font-size: 2rem;
  font-weight: normal;
  text-align: center;
  padding-bottom: 16px;

  @media (--viewportMedium) {
    font-size: 3rem;
  }
}

.info {
  color: black;
  font-family: Raleway;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
  margin: 0;
  padding: 0;
}