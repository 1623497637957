@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FEF6FA;
}


.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: var(--contentMaxWidthPages);
  padding: 32px;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

ul {
  list-style: none;
}


.title {
  color: black;
  font-family: Neuton;
  font-size: 2rem;
  font-weight: normal;
  text-align: center;
  font-weight: bold;

  @media (--viewportMedium) {
    font-size: 3rem;
  }
}

.info {
  color: black;
  font-family: Raleway;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: start;
  padding: 0;
  margin: 4px;

  @media (--viewportMedium) {
    text-align: center;
  }
}