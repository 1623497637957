@import '../../../../styles/customMediaQueries.css';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 7%;
  padding-bottom: 7%;
  gap: 30px;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: start;
    gap: unset;

  }
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  &:hover {
    cursor: pointer;
  }
}

.categoryImage {
  width: 82px;
  height: 82px;
}

.categoryName {
  color: black;
  font-family: Raleway;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
  max-width: 180px;
  padding: 0;
  margin: 0;
}